import * as React from 'react';

import { Action, Location } from 'history';
import { withRouter } from 'react-router';
import { NavLink, Route, RouteComponentProps } from 'react-router-dom';

import SearchResults from './SearchResults';

import './App.css';

interface IAppState {
  input: string;
  logoSrc: string;
}

class App extends React.Component<RouteComponentProps, IAppState> {

  constructor(props: RouteComponentProps) {
    super(props);

    this.state = { input: "", logoSrc: "/logo-header.svg" };

    this.props.history.listen(this.onRouteChange);
  }

  public render() {
    return (
      <div>
        {this._renderNavigationBar()}

        {this._renderContent()}

        {this._renderFooter()}
      </div>
    );
  }

  private onRouteChange = (location: Location, action: Action) =>
    this.setState({ input: "" });

  private _renderNavigationBar() {
    return (
      <nav className="navbar navbar-inverse" role="navigation">
        <div className="container">
          <div className="row">
            <div id="navbar" className="col-sm-12">
              <ul className="nav navbar-nav" role="tablist">
                <li>
                  <img className="navbar-logo" width="94" height="29" alt="NuGet Server"
                      src={this.state.logoSrc}
                      onError={() => this.setState({ ...this.state, logoSrc: '/logo-header-94x29.png' })} />
                </li>
                <li role="presentation"><NavLink to="/" exact={true} role="tab"><span>Packages</span></NavLink></li>
                <li role="presentation"><NavLink to="/connect"><span>Connect</span></NavLink></li>
                <li role="presentation">
                  <a role="tab" href="https://dev.azure.com/tlylz/namomo/_packaging?_a=feed&amp;feed=namofun%40Release" rel="noopener noreferrer">
                    <span>Feed</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container search-container">
          <div className="row">
            <form className="col-sm-12">
              <input
                type="text"
                className="form-control"
                autoComplete="off"
                placeholder="Search packages..."
                data-testid="search-field"
              onChange={this.handleChange} />
            </form>
          </div>
        </div>
      </nav>
    );
  }

  private _renderContent() {
    if (this.state.input.length === 0) {
      return (
        <section role="main" className="container main-container">
          <Route exact={true} path="/" render={this.renderSearch} />

          {this.props.children}
        </section>
      );
    }
    else
    {
      return (
        <section role="main" className="container main-container">
          <SearchResults input={this.state.input} />
        </section>
      );
    }
  }

  private _renderFooter() {
    return (
      <footer className="footer">
        <div className="container">
          <p className="footer-content">
            <span className="middle-shown">Based on </span>
            <span className="middle-hidden">@ </span>
            <a href="https://github.com/loic-sharma/BaGet" rel="nofollow">BaGet UI</a>
            <span className="middle-shown"> and </span>
            <span className="middle-hidden"> / </span>
            <a href="https://dev.azure.com" rel="nofollow">Azure DevOps</a>
          </p>
        </div>
      </footer>
    );
  }

  private renderSearch = (props: RouteComponentProps<any>) => (
    <SearchResults input={this.state.input} {...props} />
  );

  private handleChange = (input: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ input: input.target.value });
}

export default withRouter(App);

import { Icon } from 'office-ui-fabric-react/lib/Icon';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import timeago from 'timeago.js';
import { coerce, eq, gt, SemVer } from 'semver';

import { config, QueryHierarchy } from '../config';
import Dependencies from './Dependencies';
import { PackageType, InstallationInfo } from './InstallationInfo';
import LicenseInfo from './LicenseInfo';
import * as Registration from './Registration';
import SourceRepository from './SourceRepository';
import { Versions, IPackageVersion } from './Versions';

import './DisplayPackage.css';
import DefaultPackageIcon from "../default-package-icon-256x256.png";
import { HierarchyResult, VersionMetric } from './HierarchyResult';
import { CountedResult, TinyPackageVersion } from '../AzureDevOps';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';

interface IDisplayPackageProps {
  match: {
    params: {
      id: string;
      version?: string;
    }
  }
}

interface IPackage {
  id: string;
  hasReadme: boolean;
  description: string;
  readme: string;
  lastUpdate: Date;
  iconUrl: string;
  projectUrl: string;
  licenseUrl: string;
  downloadUrl: string;
  repositoryUrl: string;
  repositoryType?: string;
  releaseNotes: string;
  totalDownloads: number;
  packageType: PackageType;
  downloads: number;
  authors: string;
  tags: string[];
  version: string;
  normalizedVersion: string;
  versions: IPackageVersion[];
  dependencyGroups: Registration.IDependencyGroup[];
}

interface IDisplayPackageState {
  loading: boolean;
  id: string;
  fallbackNoVer: boolean;
  fallbackNugetOrg: boolean;
  package?: IPackage;
}

class DisplayPackage extends React.Component<IDisplayPackageProps, IDisplayPackageState> {

  private static readonly initialState: IDisplayPackageState = {
    loading: true,
    id: "",
    fallbackNoVer: false,
    fallbackNugetOrg: false,
    package: undefined,
  };

  private registrationController: AbortController;
  private versionController: AbortController;

  constructor(props: IDisplayPackageProps) {
    super(props);

    this.registrationController = new AbortController();
    this.versionController = new AbortController();
    this.state = DisplayPackage.initialState;
  }

  public componentWillUnmount() {
    this.registrationController.abort();
    this.versionController.abort();
    document.title = "NuGet Server";
  }

  public componentDidUpdate(previous: IDisplayPackageProps) {
    // This is used to switch between versions of the same package.
    if (previous.match.params.id !== this.props.match.params.id ||
      previous.match.params.version !== this.props.match.params.version) {
      this.registrationController.abort();
      this.versionController.abort();
      document.title = "NuGet Server";

      this.registrationController = new AbortController();
      this.versionController = new AbortController();
      this.setState(DisplayPackage.initialState);
      this.componentDidMount();
    }
  }

  private createPost(signal: AbortSignal, body: object) : RequestInit {
    return {
      signal: signal,
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(body),

      headers: (() => {
        let header = new Headers();
        header.append('Accept', 'application/json;api-version=5.0-preview.1;excludeUrls=true;enumsAsNumbers=true;msDateFormat=true;noArrayWrap=true');
        header.append('Content-Type', 'application/json');
        return header;
      })(),
    };
  }

  public componentDidMount() {
    this.fetchFromAzureDevOps(
        this.props.match.params.id.toLowerCase(),
        this.props.match.params.version)
      .then(
        state => this.setState(state));
  }

  private async fetchFromAzureDevOps(
    packageId: string,
    specifiedVersion: string | undefined,
    fallbackNoVer: boolean = false)
    : Promise<IDisplayPackageState>
  {
    const coerceVersion = coerce(specifiedVersion);

    const queryParam : { [param: string] : string } = {
      _a: "package",
      feed: config.feedName,
      package: packageId,
      protocolType: "NuGet",
      view: "versions",
    };

    if (!fallbackNoVer && coerceVersion !== null && specifiedVersion !== undefined) {
      queryParam.version = specifiedVersion;
    }

    const queryOptions = this.createPost(
      this.registrationController.signal,
      QueryHierarchy(queryParam)
    );

    const hierarchyResult = await fetch(config.getHierarchyQueryUrl(), queryOptions)
      .then(response => (response.ok) ? response.json() : null)
      .then(json => json as HierarchyResult);

    const packageAll = hierarchyResult.dataProviders['ms.feed.package-hub-data-provider'].packageDetailsResult;
    const packageFailed = packageAll.package === null || packageAll.packageVersion === null;

    if (packageFailed && !fallbackNoVer && coerceVersion !== null) {
      return await this.fetchFromAzureDevOps(packageId, undefined, true);
    } else if (packageFailed) {
      return await this.fetchFromNuGetGallery(packageId, this.props.match.params.version);
    } else {
      const packageVersionIds : string[] = [];
      const normalizedPackageName : string = packageAll.package.normalizedName;
      const version = packageAll.packageVersion;
      const anyListed = this.anyListed(packageAll.package.versions);
      const latestVersion = this.latestVersion(packageAll.package.versions, anyListed);
      const versions: IPackageVersion[] = [];
      const idMaps = new Map<string, IPackageVersion>();

      for (const entry of packageAll.package.versions) {
        if (anyListed && !entry.isListed) {
          console.log('unlisted '.concat(entry.version));
        }

        const normalizedVersion = entry.normalizedVersion;
        const coercedVersion = coerce(entry.version);

        if (coercedVersion === null) continue;

        const isCurrent = latestVersion !== null && coercedVersion !== null
          ? eq(coercedVersion, !!coerceVersion ? coerceVersion : latestVersion)
          : false;

        const ver : IPackageVersion = {
          date: new Date(0),
          downloads: 0,
          version: normalizedVersion,
          selected: isCurrent,
          listed: entry.isListed,
          id: entry.id,
          latest: entry.isLatest,
        };

        versions.push(ver);
        if (entry.id) {
          packageVersionIds.push(entry.id);
          idMaps.set(entry.id, ver);
        }
      }

      const curState : IDisplayPackageState = {
        loading: false,
        id: packageId,
        fallbackNoVer,
        fallbackNugetOrg: false,
        package: {
          id: packageAll.package.name,
          lastUpdate: this.normalizeDate(version.publishDate),
          description: version.description,
          projectUrl: version.protocolMetadata.data.projectUrl,
          licenseUrl: version.protocolMetadata.data.licenseUrl,
          authors: version.author,
          tags: version.tags,
          version: version.version,
          normalizedVersion: version.normalizedVersion,
          versions: versions,
          hasReadme: false,
          readme: "",
          dependencyGroups: [],
          downloads: 0,
          totalDownloads: 0,
          downloadUrl: '',
          iconUrl: "",
          releaseNotes: "",
          packageType: 0,
          repositoryUrl: "",
        },
      };

      const guid = packageAll.package.id;

      const url = `${config.getAdoRestfulUrl()}/${guid}/VersionMetricsBatch`;
      const options = this.createPost(this.versionController.signal, { packageVersionIds });
      const promise1 = fetch(url, options).then(response => {
        return (response.ok) ? response.json() : null;
      }).then(json => {
        const result = json as VersionMetric[];
        let latestDownload = 0;
        let totalDownload = 0;
        for (const entry of result) {
          var ver = idMaps.get(entry.packageVersionId);
          if (ver === undefined) continue;
          ver.downloads = entry.downloadCount;
          if (ver.latest) latestDownload = entry.downloadCount;
          totalDownload += entry.downloadCount;
        }

        if (curState.package === undefined) return;
        curState.package.totalDownloads = totalDownload;
        curState.package.downloads = latestDownload;
      });

      const url2 = `${config.getAdoRestfulUrl()}/${guid}/Versions`;
      const promise2 = fetch(url2, { signal: this.versionController.signal }).then(response => {
        return (response.ok) ? response.json() : null;
      }).then(json => {
        const result = json as CountedResult<TinyPackageVersion>;
        for (const entry of result.value) {
          var ver = idMaps.get(entry.id);
          ver && (ver.date = this.normalizeDate(entry.publishDate));
        }
      });

      const url3 = `${config.getNuGetServiceUrl()}/v3/registrations2/${normalizedPackageName}/page/${version.normalizedVersion}/${version.normalizedVersion}.json`;
      const promise3 = fetch(url3, { signal: this.versionController.signal }).then(response => {
        return (response.ok) ? response.json() : null;
      }).then(json => {
        const result = json as Registration.IRegistrationPage;
        const pkg = result.items[0];

        if (curState.package === undefined) return;
        curState.package.dependencyGroups = pkg.catalogEntry.dependencyGroups;
        curState.package.downloadUrl = pkg.packageContent;
        curState.package.hasReadme = pkg.catalogEntry.hasReadme;
        curState.package.releaseNotes = pkg.catalogEntry.releaseNotes;
        curState.package.repositoryUrl = pkg.catalogEntry.repositoryUrl;
        curState.package.packageType = this.normalizePackageType(pkg.catalogEntry.packageTypes);
      });

      await Promise.all([promise1, promise2, promise3]);
      return curState;
    }
  }

  private async fetchFromNuGetGallery(
    packageId: string,
    specifiedVersion: string | undefined)
    : Promise<IDisplayPackageState>
  {
    const coerceVersion = coerce(specifiedVersion);
    const url = config.getNuGetGalleryUrl(packageId);
    const registration = await fetch(url, { signal: this.versionController.signal, mode: "cors", redirect: "follow" })
      .then(resp => resp.ok ? resp.json() : null)
      .catch(() => null)
      .then(json => json === null ? null : json as Registration.IRegistrationIndex);

    if (registration === null) {
      return { loading: false, id: packageId, fallbackNugetOrg: false, fallbackNoVer: false };
    }

    let current : Registration.IRegistrationPageItem | null = null;
    let latestVersion : { entry: Registration.IRegistrationPageItem, version: IPackageVersion } | null = null;
    const sortedItems : Registration.IRegistrationPageItem[] = [];
    const packageVersions : IPackageVersion[] = [];
    for (const page of registration!.items) {
      for (const entry of page.items) {
        const version : IPackageVersion = {
          version: entry.catalogEntry.version,
          listed: entry.catalogEntry.listed,
          selected: false,
          downloads: 0,
          date: this.normalizeDate(entry.catalogEntry.published),
          latest: false,
        };

        const currentVersion = coerce(entry.catalogEntry.version)!;
        sortedItems.push(entry);
        packageVersions.push(version);

        console.log({ entry, version, currentVersion });
        if (currentVersion.raw === version.version) {
          latestVersion = { entry, version };
        }

        if (coerceVersion !== null && eq(coerceVersion, currentVersion)) {
          current = entry;
          version.selected = true;
        }
      }
    }

    sortedItems.reverse();
    packageVersions.reverse();
    latestVersion = latestVersion ?? { entry: sortedItems[0], version: packageVersions[0] };
    latestVersion!.version.latest = true;
    const fallbackNoVer = current === null && specifiedVersion !== undefined;
    if (current === null) {
      latestVersion.version.selected = true;
      current = latestVersion.entry;
    }

    const version = current.catalogEntry;
    return {
      loading: false,
      id: packageId,
      fallbackNoVer,
      fallbackNugetOrg: true,
      package: {
        id: version.id,
        lastUpdate: this.normalizeDate(version.published),
        description: version.description,
        projectUrl: version.projectUrl,
        licenseUrl: version.licenseUrl,
        authors: version.authors,
        tags: version.tags,
        version: version.version,
        normalizedVersion: version.version,
        versions: packageVersions,
        hasReadme: false,
        readme: '',
        dependencyGroups: version.dependencyGroups,
        downloads: 0,
        totalDownloads: 0,
        downloadUrl: current.packageContent,
        iconUrl: version.iconUrl,
        releaseNotes: version.releaseNotes,
        packageType: this.normalizePackageType(version.packageTypes),
        repositoryUrl: version.repositoryUrl,
      },
    };
  }

  public render() {
    if (this.state.loading) {
      return (
        <div className="dy-5 main-container">
          <Spinner size={SpinnerSize.large} label="Loading package information..." />
        </div>
      );
    } else if (!this.state.package) {
      document.title = "NuGet Server | Not Found";
      return (
        <div>
          <h2>Oops, package not found...</h2>
          <p>Could not find package '{this.state.id}'.</p>
          <p>You can try searching on <a href={`https://www.nuget.org/packages?q=${this.state.id}`} target="_blank" rel="noopener noreferrer">nuget.org</a> package.</p>
        </div>
      );
    } else {
      document.title = `NuGet Server | ${this.state.package.id} ${this.state.package.version}`;
      return (
        <div className="row display-package page-bottom-2em">
          <aside className="col-sm-1 package-icon hidden-xs">
            <img
              src={DefaultPackageIcon}
              className="img-responsive"
              alt="The package icon" />
          </aside>
          <article className="col-sm-8 package-details-main">
            <div className="package-title">
              <h1>
                {this.state.package.id}
                <small className="text-nowrap">{this.state.package.version}</small>
              </h1>
            </div>

            {this.state.fallbackNugetOrg &&
              <div className="icon-text alert alert-info">
                <Icon iconName="Error" />
                &nbsp;The specified package was only found on public NuGet source.
              </div>
            }

            {this.state.fallbackNoVer &&
              <div className="icon-text alert alert-warning">
                <Icon iconName="Warning" />
                &nbsp;The specified version {this.props.match.params.version} was not found. You have been taken to version {this.state.package.version}.
              </div>
            }

            {!this.state.fallbackNugetOrg && this.state.package.version !== this.state.package.versions[0].version &&
              <div className="icon-text alert alert-secondary">
                <Icon iconName="Info" />
                &nbsp;There is a newer version of this package available.
                See the version list below for details.
              </div>
            }

            <InstallationInfo
              id={this.state.package.id}
              version={this.state.package.normalizedVersion}
              packageType={this.state.package.packageType} />

            {this.state.package.hasReadme
              ? <ExpandableSection title="Documentation" expanded={true}>
                  <ReactMarkdown source={this.state.package.readme} />
                </ExpandableSection>
              : <div className="package-description">
                  {this.state.package.description}
                </div>
            }

            {this.state.package.releaseNotes &&
              <ExpandableSection title="Release Notes" expanded={false}>
                <div className="package-release-notes" >{this.state.package.releaseNotes}</div>
              </ExpandableSection>
            }

            <ExpandableSection title="Dependencies" expanded={!this.state.fallbackNugetOrg}>
              <Dependencies dependencyGroups={this.state.package.dependencyGroups} />
            </ExpandableSection>

            <ExpandableSection title="Versions" expanded={!this.state.fallbackNugetOrg}>
              {this.state.package.versions === undefined
              ? <Spinner className="put-left" label="Still loading..." ariaLive="assertive" labelPosition="right" />
              : <Versions packageId={this.state.id} versions={this.state.package.versions} fallbackNugetOrg={this.state.fallbackNugetOrg} />}
            </ExpandableSection>
          </article>
          <aside className="col-sm-3 package-details-info">
            <div>
              <h2>Info</h2>

              <ul className="list-unstyled ms-Icon-ul">
                <li>
                  <Icon iconName="History" className="ms-Icon" />
                  Last updated {timeago().format(this.state.package.lastUpdate)}
                </li>
                {this.state.package.projectUrl &&
                  <li>
                    <Icon iconName="Globe" className="ms-Icon" />
                    <a href={this.state.package.projectUrl} className="word-break-all">{this.state.package.projectUrl}</a>
                  </li>
                }
                <SourceRepository url={this.state.package.repositoryUrl} type={this.state.package.repositoryType} />
                <LicenseInfo url={this.state.package.licenseUrl} />
                <li>
                  <Icon iconName="CloudDownload" className="ms-Icon" />
                  <a href={this.state.package.downloadUrl}>Download package</a>
                </li>
              </ul>
            </div>

            {!this.state.fallbackNugetOrg &&
            <div>
              <h2>Statistics</h2>

              <ul className="list-unstyled ms-Icon-ul">
                <li>
                  <Icon iconName="Download" className="ms-Icon" />
                  {this.state.package.totalDownloads || '0'} total downloads
                </li>
                <li>
                  <Icon iconName="GiftBox" className="ms-Icon" />
                  {this.state.package.downloads || '0'} downloads of latest version
                </li>
              </ul>
            </div>
            }

            {this.state.package.authors &&
            <div>
              <h2>Authors</h2>

              <ul className="list-unstyled ms-Icon-ul">
                {this.state.package.authors.split(',').map((author) => (
                <li>
                  <Icon iconName="Contact" className="ms-Icon" />
                  {author}
                </li>
                ))}
              </ul>
            </div>
            }
          </aside>
        </div>
      );
    }
  }

  private loadDefaultIcon = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = DefaultPackageIcon;
  }

  private normalizeDate(original: string) : Date {
    if (original === undefined) {
      return new Date('undefined');
    } else if (original.startsWith('/Date(')) {
      return new Date(parseInt(original.substr(6, 13)));
    } else {
      return new Date(original);
    }
  }

  private normalizeVersion(version: string): string {
    const buildMetadataStart = version.indexOf('+');
    return buildMetadataStart === -1
      ? version
      : version.substring(0, buildMetadataStart);
  }

  private normalizePackageType(packageTypes?: string[]) : PackageType {
    return (packageTypes && packageTypes.indexOf("DotnetTool") !== -1)
      ? PackageType.DotnetTool
      : (packageTypes && packageTypes.indexOf("Template") !== -1)
        ? PackageType.DotnetTemplate
        : PackageType.Dependency;
  }

  private anyListed(index: TinyPackageVersion[]): boolean {
    for (const entry of index) {
      if (entry.isListed) return true;
    }
    return false;
  }

  private latestVersion(index: TinyPackageVersion[], shouldListed: boolean): SemVer | null {
    let latestVersion: SemVer | null = null;
    for (const entry of index) {
      if (shouldListed && !entry.isListed) continue;

      let entryVersion = coerce(entry.version);
      if (!!entryVersion) {
        if (latestVersion === null || gt(entryVersion, latestVersion)) {
          latestVersion = entryVersion;
        }
      }
    }

    return latestVersion;
  }
}

interface IExpandableSectionProps {
  title: string;
  expanded: boolean;
}

interface IExpandableSectionState {
  expanded: boolean;
}

class ExpandableSection extends React.Component<IExpandableSectionProps, IExpandableSectionState> {
  constructor(props: IExpandableSectionProps) {
    super(props);

    this.state = { ...props };
  }

  public render() {
    if (this.state.expanded) {
      return (
        <div className="expandable-section">
          <h2>
            <button type="button" onClick={this.collapse} className="link-button">
              <Icon iconName="ChevronDown" className="ms-Icon" />
              <span>{this.props.title}</span>
            </button>
          </h2>

          {this.props.children}
        </div>
      );
    } else {
      return (
        <div className="expandable-section">
          <h2>
            <button type="button" onClick={this.expand} className="link-button">
              <Icon iconName="ChevronRight" className="ms-Icon" />
              <span>{this.props.title}</span>
            </button>
          </h2>
        </div>
      );
    }
  }

  private collapse = () => this.setState({expanded: false});
  private expand = () => this.setState({expanded: true});
}

export default DisplayPackage;
